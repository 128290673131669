
<template>
    <div>
      <Row :gutter="8" class="m-b-10">
        <i-col  :xs="24" :sm="12" :md="8" :lg="8" class="m-b-5">
                <DatePicker size="small" placeholder="任务发起时间" v-model="taskDate"  @on-change="scheduleChange" type="daterange" style="width:100%"></DatePicker>
            </i-col>
        <i-col :xs="24" :sm="12" :md="8" :lg="8">
          <i-input size="small" placeholder="关键字：支持“发起者/所属客户”等模糊查询" v-model="query.keyword">
          </i-input>
        </i-col>
        <i-col :xs="24" :sm="12" :md="6" :lg="6">
          <i-button size="small" class="m-r-5" type="primary" icon="ios-search" @click="handleSearchData">搜索</i-button>
          <i-button size="small" type="success"  @click="handleSearchWaring">查看待补充任务</i-button>
        </i-col>
      </Row>
      <Table size="small"
          stripe
          :data="taskList"
          :columns="tableColumns"
        ></Table>

      <div class="paging_style">
        <Page size="small" :total="total" :page-size="query.pageSize" show-total show-elevator :current="query.pageNumber" @on-change="handlePageNumberChange"></Page>
      </div>

    </div>
</template>

<script>
import { getTaskPage } from '@/api/approval/approvaltask'
import { formatApprovalStatus, formatApprovalProcessStatus } from '@/utils/tagStatus'
import { GetDateStr } from '@/utils/dateFormat'
export default {
  data () {
    return {
      taskList: [],
      tableColumns: [
        {
          title: '任务类型',
          width: 150,
          render: (h, data) => {
            const showLabels = [
              h('span', data.row.processtypeName)
            ]

            if (data.row.processtypeFeature === 1) {
              showLabels.push(h('br'))
              showLabels.push(h('tag', { props: { color: 'red', type: 'border' } }, data.row.processtypeFeatureName))
            }

            return h('div', showLabels)
          }
        },
        {
          title: '任务描述',
          render: (h, data) => {
            const showLabels = []

            if (data.row.wxAbstractDesc) {
              data.row.contentArray.forEach(element => {
                if (element.indexOf('类型：') < 0 && element.indexOf('销售：') < 0) {
                  showLabels.push(h('p', element))
                }
              })
            } else {
              showLabels.push(h('p', '名称：' + data.row.businessName || data.row.processTag))
            }

            return h('div', showLabels)
          }
        },
        { title: '发起者', key: 'userName', align: 'center', width: 120 },
        { title: '发起时间', key: 'createTime', align: 'center', width: 150 },
        {
          title: '任务状态',
          align: 'center',
          width: 120,
          render: (h, data) => {
            let isWarning
            if (data.row.processSupplementFeature === 1) {
              isWarning = h('tag', { props: { color: '#a59426' } }, data.row.processSupplementFeatureName)
            }

            return h('div', [
              formatApprovalStatus(h, data.row.status, data.row.statusName),
              isWarning
            ])
          }
        },
        {
          title: '最新动态',
          render: (h, data) => {
            if (data.row.latestApproverFinishTime === null) {
              return formatApprovalProcessStatus(h, data.row.latestReviewStatus, data.row.latestReviewStatusName)
            } else {
              const message = []
              if (data.row.latestApproverUserName !== '') {
                message.push(h('span', '操作人：' + data.row.latestApproverUserName))
                message.push(h('br'))
              }

              if (data.row.latestApproverFinishTime !== '') {
                message.push(h('span', '操作时间：' + data.row.latestApproverFinishTime))
                message.push(h('br'))
              }

              message.push(formatApprovalProcessStatus(h, data.row.latestReviewStatus, data.row.latestReviewStatusName))
              return h('div', message)
            }
          }
        },
        {
          title: '操作',
          align: 'center',
          width: 80,
          render: (h, params) => {
            return h('div', [
              h('a',
                {
                  on: {
                    click: () => {
                      this.$store.commit('set_approvalTask_pageType', 'detail')
                      this.$store.commit('set_approvalTask_detailId', params.row.id)
                    }
                  }
                }, '查看')
            ])
          }
        }
      ],
      total: 0,
      query: {
        orderByCreateTimeAsc: false,
        keyword: '',
        pageNumber: this.pageNumber,
        pageSize: 15
      },
      taskDate: []
    }
  },
  created () {
    this.query.processtype = this.taskType && this.taskType !== -1 ? this.taskType : null
    this.query.status = this.summaryType === 'pendding' ? 0 : null
    this.query.approverUserId = this.userId
    this.query.keyword = this.keyword
    this.query.pageNumber = this.pageNumber
    this.initPageData()
  },
  computed: {
    keyword () {
      return this.$store.state.approvalTask.keyword
    },
    pageNumber () {
      return this.$store.state.approvalTask.pageNumber
    },
    summaryType () {
      return this.$store.state.approvalTask.summaryType
    },
    userId () {
      return this.$store.state.approvalTask.userId
    },
    taskType () {
      return this.$store.state.approvalTask.taskType
    }
  },

  methods: {

    scheduleChange () {
      const shcedule = this.taskDate
      if (shcedule[0] === '' || shcedule[1] === '') {
        this.query.startDate = ''
        this.query.endDate = ''
      } else {
        this.query.startDate = GetDateStr(shcedule[0], 1)
        this.query.endDate = GetDateStr(shcedule[1], 1)
      }
    },
    initPageData () {
      this.formatTableColumn()

      getTaskPage(this.query).then(res => {
        res.list.forEach(element => {
          element.contentArray = element.wxAbstractDesc.split('\n').filter(x => x.indexOf('审批状态') === -1)
        })

        this.taskList = res.list
        this.total = res.totalRow
      })
    },
    formatTableColumn () {
      this.query.processtype = this.taskType === -1 ? null : this.taskType
      // 先移除掉已添加的列
      if (this.tableColumns[1].title === '任务名称' || this.tableColumns[1].title === '客户名称' || this.tableColumns[1].title === '任务类型') {
        this.tableColumns.splice(1, 1)
      }

      // 只有在通用审批或者罚单审批时，显示出任务名称列
      if (this.taskType === 13 || this.taskType === 14 || this.taskType === 15) {
        const title = this.taskType === 15 ? '任务类型' : '任务名称'
        this.tableColumns.splice(1, 0, { title: title, key: 'businessName' })
      } else if (this.taskType === 6 || this.taskType === 3 || this.taskType === 10 || this.taskType === 8 || this.taskType === 9 || this.taskType === 12 || this.taskType === 18) {
        this.tableColumns.splice(1, 0, { title: '客户名称', key: 'processTag' })
      }
    },
    handlePageNumberChange (page) {
      this.$store.commit('set_approvalTask_pageNumber', page)
      this.query.pageNumber = page
      this.initPageData()
    },
    handleSearchData () {
      this.$store.commit('set_approvalTask_keyword', this.query.keyword)
      this.reloadPage()
    },
    handleSearchWaring () {
      this.query.processSupplementFeature = 1
      this.query.pageNumber = 1
      this.taskList = []
      this.initPageData()
    },
    reloadPage () {
      this.query.pageNumber = 1
      this.query.processSupplementFeature = null
      this.taskList = []
      this.initPageData()
    }
  },
  watch: {
    summaryType (val) {
      this.query.status = (val === 'pendding' ? 0 : null)
      this.reloadPage()
    },
    taskType (val) {
      this.reloadPage()
    }
  }
}
</script>
